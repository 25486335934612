import React from 'react';

import { ISharedIconProps } from './SharedIcon-types';

/** #### SVG chevron-left icon
 *
 * - From https://www.figma.com/design/ufkdxfHbf69NTZJ2Rqz09T/HX-DS-Vision?node-id=6628-376&m=dev
 * - Added `className={className}` and `style={{ transform: ... }}`
 * - Changed `fill="#202835"` to `fill={fill}`
 * - Removed `fill-rule="evenodd" clip-rule="evenodd"`
 */
export const ChevronLeftIcon = ({
  className = '',
  fill = '#ffffff',
  scale = 1,
  width = 24,
  height = 24
}: ISharedIconProps) => (
  <svg
    className={className}
    fill="none"
    style={{ transform: `scale(${scale})` }}
    viewBox="0 0 24 24"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6464 18.3536L15.3535 17.6464L9.70707 12L15.3535 6.35355L14.6464 5.64645L8.29286 12L14.6464 18.3536Z"
      fill={fill}
    />
  </svg>
);
