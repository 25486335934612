import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { Shimmer } from '@atoms';

const HeroSlider = ({
  totalItems,
  autoScrollInterval,
  carouselPosition,
  carouselSnapPoints
}: ISliderProps) => {
  const [currentItem, setCurrentItem] = useState(0);
  const [isForcingRefresh, setIsForcingRefresh] = useState(
    Boolean(autoScrollInterval)
  );

  useEffect(() => {
    if (carouselPosition === undefined) return;

    let newCurrentItem = currentItem;
    for (let i = 0; i < totalItems; i += 1) {
      if (
        carouselPosition * totalItems >= i &&
        carouselPosition * totalItems <= i + 1
      ) {
        newCurrentItem = i;
      }
    }

    if (newCurrentItem === currentItem) return;
    setCurrentItem(newCurrentItem);
  }, [carouselPosition]);

  const getNextItemIndex = (itemIndex: number) => {
    if (itemIndex === totalItems - 1) return 0;
    return itemIndex + 1;
  };

  const getTransitionStyle = (itemIndex: number) =>
    itemIndex === getNextItemIndex(currentItem) &&
    !isForcingRefresh &&
    autoScrollInterval
      ? {
          transitionDuration: `${Math.round(autoScrollInterval / 1000)}s`
        }
      : undefined;

  useEffect(() => {
    if (!autoScrollInterval) return;
    setIsForcingRefresh(false);
  }, []);

  const sliderButtons = useMemo(
    () =>
      new Array(totalItems).fill(0).map((_, i) => (
        <div
          style={getTransitionStyle(i)}
          className={clsx('rounded-full flex h-0.5 grow-[1]', {
            'carousel-slider-transitioning':
              autoScrollInterval &&
              i === getNextItemIndex(currentItem) &&
              !isForcingRefresh,
            'bg-white': i === currentItem,
            'bg-light-black': i !== currentItem
          })}
          key={i}
        >
          <div className="flex-1" />
        </div>
      )),
    [totalItems, currentItem, isForcingRefresh, carouselSnapPoints]
  );

  return (
    <Shimmer isShimmering={!carouselSnapPoints}>
      <div className="z-30 flex items-center justify-center w-full gap-2 bg-transparent">
        {sliderButtons}
      </div>
    </Shimmer>
  );
};

export default HeroSlider;
