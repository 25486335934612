const intervalMapper = (
  localSmoothPosition: number,
  localGridPositions: number[],
  globalGridPositions: number[]
) => {
  if (!localGridPositions || !globalGridPositions) {
    throw Error('Grids are required');
  }
  if (localGridPositions.length !== globalGridPositions.length) {
    throw Error('Grid dimensions must match');
  }
  if (
    localSmoothPosition < localGridPositions[0] ||
    localSmoothPosition > localGridPositions[localGridPositions.length - 1]
  ) {
    throw Error('Provide a position within the local grid');
  }

  const relativeLocalGridPositions = localGridPositions
    .map((gridPoint) => localSmoothPosition - gridPoint)
    .map((gridPoint) => (gridPoint < 0 ? Infinity : gridPoint));

  const closestLocalGridPositionIndex = relativeLocalGridPositions.indexOf(
    Math.min(...relativeLocalGridPositions)
  );

  const localInterval =
    (localGridPositions[closestLocalGridPositionIndex + 1] || 2) -
    localGridPositions[closestLocalGridPositionIndex];

  const localPositionRelativeToInterval =
    (localSmoothPosition - localGridPositions[closestLocalGridPositionIndex]) /
    localInterval;

  const globalInterval =
    (globalGridPositions[closestLocalGridPositionIndex + 1] || 2) -
    globalGridPositions[closestLocalGridPositionIndex];

  const globalPosition =
    globalGridPositions[closestLocalGridPositionIndex] +
    globalInterval * localPositionRelativeToInterval;

  return globalPosition;
};

export default intervalMapper;
