import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import { useOnLoad } from '@hooks/useOnLoad';
import { clamp, intervalMapper } from '@utils';

const Slider = ({
  totalItems,
  carouselPosition,
  currentItem = 0,
  carouselSnapPoints,
  enableSnap = false,
  mode,
  onChange
}: ISliderProps) => {
  const [position, setPosition] = useState(0);
  const [sliderPercentageWidth] = useState(Math.round((1 / totalItems) * 100));
  const [sliderSnapPoints] = useState(
    new Array(totalItems).fill(0).map((_, index) => index / (totalItems - 1))
  );
  const sliderRef = useRef<HTMLButtonElement>(null);
  const relativePosition = useRef(0);
  const startPosition = useRef(0);
  const sliderWidth = useRef(0);
  const isSliding = useRef(false);

  const updateSliderWidth = () => {
    if (!sliderRef.current?.parentElement) return;

    sliderWidth.current =
      sliderRef.current.parentElement.offsetWidth -
      sliderRef.current.offsetWidth;
  };

  const onDrag = (event: MouseEvent) => {
    const newPosition = clamp(
      event.pageX - startPosition.current,
      0,
      sliderWidth.current
    );

    setPosition(newPosition);

    event.stopPropagation();
    event.preventDefault();
  };

  const stopDrag = (event: MouseEvent) => {
    if (enableSnap) {
      const sliderPosition = relativePosition.current;

      const relativeSnapPositions = sliderSnapPoints.map((snapPoint) =>
        Math.abs(snapPoint - sliderPosition)
      );

      const closestSnapPositionIndex = relativeSnapPositions.indexOf(
        Math.min(...relativeSnapPositions)
      );
      setPosition(
        sliderSnapPoints[closestSnapPositionIndex] * sliderWidth.current
      );

      if (onChange && carouselSnapPoints) {
        onChange(
          intervalMapper(
            sliderSnapPoints[closestSnapPositionIndex],
            sliderSnapPoints,
            carouselSnapPoints
          )
        );
      }
    }

    event.stopPropagation();

    document.removeEventListener('pointermove', onDrag);
    document.removeEventListener('pointerup', stopDrag);

    isSliding.current = false;
  };

  const startDrag = (event: React.MouseEvent) => {
    if (sliderRef.current) {
      isSliding.current = true;

      startPosition.current = event.pageX - sliderRef.current.offsetLeft;

      event.stopPropagation();
      event.preventDefault();
      document.addEventListener('pointermove', onDrag);
      document.addEventListener('pointerup', stopDrag);
    }
  };

  const onResize = () => {
    updateSliderWidth();
    setPosition(relativePosition.current * sliderWidth.current);
  };

  useEffect(() => {
    if (
      !carouselSnapPoints ||
      !sliderSnapPoints ||
      carouselPosition === undefined ||
      carouselPosition === null
    )
      return;

    const newSliderRelativePosition = intervalMapper(
      carouselPosition,
      carouselSnapPoints,
      sliderSnapPoints
    );
    setPosition(newSliderRelativePosition * sliderWidth.current);
  }, [carouselSnapPoints, currentItem]);

  useEffect(() => {
    if (
      !carouselSnapPoints ||
      !sliderSnapPoints ||
      carouselPosition === undefined ||
      carouselPosition === null
    )
      return;

    const newSliderRelativePosition = intervalMapper(
      carouselPosition,
      carouselSnapPoints,
      sliderSnapPoints
    );
    setPosition(newSliderRelativePosition * sliderWidth.current);
  }, [carouselPosition]);

  useEffect(() => {
    const localSliderRelativePosition = position / sliderWidth.current;
    relativePosition.current = localSliderRelativePosition;

    if (!isSliding.current || !carouselSnapPoints) {
      return;
    }

    const sliderPositionRelativeToCarousel = intervalMapper(
      localSliderRelativePosition,
      sliderSnapPoints,
      carouselSnapPoints
    );

    onChange?.(sliderPositionRelativeToCarousel);
  }, [position]);

  useOnLoad(updateSliderWidth, onResize);

  return (
    <div
      className={clsx('carousel-slider relative w-full h-[5px] mt-5', {
        'bg-warm-gray-3 bg-opacity-50': mode === 'secondary'
      })}
    >
      <button
        tabIndex={-1}
        aria-label="Slide"
        ref={sliderRef}
        onPointerDown={startDrag}
        style={{
          left: position || 0,
          width: `${sliderPercentageWidth}%`
        }}
        className={clsx('absolute h-4  carousel-slider-button', {
          'bg-flush-red': mode === 'primary',
          'bg-white': mode === 'secondary'
        })}
      ></button>
    </div>
  );
};

export default Slider;
