const getSnapPoints = ({
  numberOfItems,
  itemWidth,
  containerWidth,
  containerScrollWidth,
  itemMargin,
  isNoBoundaryMargin
}: {
  numberOfItems: number;
  itemWidth: number;
  containerWidth: number;
  containerScrollWidth: number;
  itemMargin: number;
  isNoBoundaryMargin: boolean;
}) => {
  const boundaryMargin = isNoBoundaryMargin ? 2 * itemMargin : 3 * itemMargin;

  const boundarySnapPoint =
    boundaryMargin + 1.5 * itemWidth - containerWidth / 2;

  let newSnapPoints: number[];
  switch (numberOfItems) {
    case 1:
      newSnapPoints = [0];
      break;
    case 2:
      newSnapPoints = [0, containerScrollWidth];
      break;
    case 3:
      newSnapPoints = [0, boundarySnapPoint, containerScrollWidth];
      break;
    default:
      newSnapPoints = [
        0,
        ...new Array(numberOfItems - 2)
          .fill(0)
          .map((_, index) =>
            Math.floor(boundarySnapPoint + index * (2 * itemMargin + itemWidth))
          ),
        containerScrollWidth
      ];
      break;
  }

  return newSnapPoints.map((snapPoint) => (snapPoint < 0 ? 0 : snapPoint));
};

export default getSnapPoints;
