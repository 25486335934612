import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { ButtonModes } from '@constants';
import { Shimmer } from '@atoms';

const DiscreteSlider = ({
  size = 'normal',
  mode = 'secondary',
  isDark = false,
  totalItems,
  autoScrollInterval,
  carouselPosition,
  carouselSnapPoints,
  hasDiscretePadding = false,
  hasLaptopPadding = false,
  onChange,
  sliderPalette = 'normal',
  sliderShape = 'bar'
}: ISliderProps) => {
  const [currentItem, setCurrentItem] = useState(0);
  const [isForcingRefresh, setIsForcingRefresh] = useState(
    Boolean(autoScrollInterval)
  );

  useEffect(() => {
    if (carouselPosition === undefined) return;

    let newCurrentItem = currentItem;
    for (let i = 0; i < totalItems; i += 1) {
      if (
        carouselPosition * totalItems >= i &&
        carouselPosition * totalItems <= i + 1
      ) {
        newCurrentItem = i;
      }
    }

    if (newCurrentItem === currentItem) return;
    setCurrentItem(newCurrentItem);
  }, [carouselPosition]);

  const getNextItemIndex = (itemIndex: number) => {
    if (itemIndex === totalItems - 1) return 0;
    return itemIndex + 1;
  };

  const getTransitionStyle = (itemIndex: number) =>
    itemIndex === getNextItemIndex(currentItem) &&
    !isForcingRefresh &&
    autoScrollInterval
      ? {
          transitionDuration: `${Math.round(autoScrollInterval / 1000)}s`
        }
      : undefined;

  const onClick = (itemIndex: number) => {
    if (itemIndex === currentItem && autoScrollInterval) {
      setIsForcingRefresh(true);
      setTimeout(() => setIsForcingRefresh(false), 0);
    }

    setCurrentItem(itemIndex);
    onChange?.(carouselSnapPoints?.[itemIndex] || 0);
  };

  useEffect(() => {
    if (!autoScrollInterval) return;
    setIsForcingRefresh(false);
  }, []);

  const sliderButtons = useMemo(
    () =>
      new Array(totalItems).fill(0).map((_, i) => (
        <div
          style={getTransitionStyle(i)}
          className={clsx('rounded-full flex h-1', {
            'w-12 tablet:w-[146px] h-0.5': size === 'medium',
            'w-12 h-0.5': size === 'normal',
            'w-3 h-[1px]': size === 'small',
            'grow-[1]': size === 'full',
            'carousel-slider-transitionable':
              autoScrollInterval &&
              i !== currentItem &&
              mode === ButtonModes.primary &&
              isDark,
            'bg-white':
              mode === ButtonModes.primary &&
              isDark &&
              i === currentItem &&
              !autoScrollInterval &&
              sliderPalette === 'normal',
            'bg-primary-hx-indigo':
              mode === ButtonModes.primary &&
              !isDark &&
              i === currentItem &&
              !autoScrollInterval &&
              sliderPalette === 'normal',
            'carousel-slider-transitioned':
              mode === ButtonModes.primary && i === currentItem && isDark,
            'carousel-slider-transitioning':
              autoScrollInterval &&
              i === getNextItemIndex(currentItem) &&
              !isForcingRefresh &&
              isDark,
            'bg-warm-gray-4':
              (mode === ButtonModes.secondary || isDark) &&
              i === currentItem &&
              sliderPalette === 'normal',
            'bg-black':
              mode === ButtonModes.tertiary &&
              i === currentItem &&
              sliderPalette === 'normal',
            'bg-hx-gray':
              i !== currentItem && isDark && sliderPalette === 'normal',
            'bg-indigo-light-300':
              i !== currentItem && !isDark && sliderPalette === 'normal',
            'bg-light-black':
              i !== currentItem && isDark && sliderPalette === 'normal',
            'bg-hxv-primary-sand-30':
              i === currentItem && sliderPalette === 'onDarkIndigo',
            // 0.7 opacity, so dots look right on primary-sand-30, and also on primary-sand-40:
            'bg-hxv-primary-sand-60 opacity-70':
              i !== currentItem && sliderPalette === 'onLightSand',
            'bg-hxv-indigo-40':
              i !== currentItem && sliderPalette === 'onDarkIndigo',
            'bg-hxv-indigo-80':
              i === currentItem && sliderPalette === 'onLightSand',
            'w-2 h-2 mx-[2px] mt-[-25px] translate-y-[-2px]':
              sliderShape === 'dot',
            'tabletLandscape:w-[10px] tabletLandscape:h-[10px] tabletLandscape:mx-[3px]':
              sliderShape === 'dot'
          })}
          key={i}
        >
          <button
            tabIndex={-1}
            className="flex-1"
            aria-label={`Show image ${i + 1}`}
            onClick={() => onClick(i)}
          />
        </div>
      )),
    [totalItems, currentItem, isForcingRefresh, carouselSnapPoints]
  );

  return (
    <Shimmer isShimmering={!carouselSnapPoints}>
      <div
        className={clsx(
          'z-30 flex w-full items-center justify-center bg-transparent',
          {
            'gap-2': size === 'normal' || size === 'full' || size === 'medium',
            'gap-0.5': size === 'small',
            'gap-1': !size,
            'my-6': hasDiscretePadding,
            'laptop:px-[8.2rem]': hasLaptopPadding
          }
        )}
      >
        {sliderButtons}
      </div>
    </Shimmer>
  );
};

export default DiscreteSlider;
