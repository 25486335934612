import React from 'react';

import { ISharedIconProps } from './SharedIcon-types';

/** #### SVG chevron-right icon
 *
 * - From https://www.figma.com/design/ufkdxfHbf69NTZJ2Rqz09T/HX-DS-Vision?node-id=6628-376&m=dev
 * - Added `className={className}` and `style={{ transform: ... }}`
 * - Changed `fill="#202835"` to `fill={fill}`
 * - Removed `fill-rule="evenodd" clip-rule="evenodd"`
 */
export const ChevronRightIcon = ({
  className = '',
  fill = '#ffffff',
  scale = 1,
  width = 24,
  height = 24
}: ISharedIconProps) => (
  <svg
    className={className}
    fill="none"
    style={{ transform: `scale(${scale})` }}
    viewBox="0 0 24 24"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.35359 18.3536L8.64648 17.6464L14.2929 12L8.64648 6.35355L9.35359 5.64645L15.7071 12L9.35359 18.3536Z"
      fill={fill}
    />
  </svg>
);
